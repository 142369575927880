.container {
  max-width: 600px;
  margin: auto;
}

.headerContainer {
  display: flex;
}

.logo {
  width: 36px;
  height: 36px;
  margin-right: 4px;
}

.logoTitle {
  font-size: 20px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}

.productContainer {
  padding: 10px;
}

.sellerContainer {
  display: flex;
}

.userProfileImage {
  width: 30px;
  height: 30px;
  margin-right: 4px;
  border-radius: 24px;
  object-fit: cover;
}

.sellerName {
  margin-top: auto;
  margin-bottom: auto;
}

.productImageSliderContainer {
  margin-top: 10px;
/*  display: inline-block;*/
}

.productImageSliderSelectedImage {
  height: 300px;
}

.productImageSliderThumbnail {
  height: 50px;
}

.productTitle {
  margin-top: 10px;
  font-weight: 700;
}

.price {
  margin-top: 10px;
}

.discountPrice {
  margin-right: 10px;
}

.originalPrice {
  margin-right: 10px;
  text-decoration: line-through;
  color: #666;
}

.discountRate {
  color: #FDA408;
}

.rating {
  margin-top: 10px;
}

.sectionContainer {
  border-top: 1px solid #ccc;
}

.sectionTitle {
  font-weight: bold;
}

.commentItemContainer {
  display: flex;
  margin-bottom: 10px;
}

.commentAuthorProfileImageContainer {
  margin-bottom: auto;
}

.commentInfoContainer {
  display: inline-block;
  margin-left: 10px;
}

.commentAuthorName {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.commentInfo {
  margin-top: auto;
  margin-bottom: auto;
  color: #666;
  font-size: 14px;
}

.comment {
  margin-top: 4px;
}

.productListItemLink {
  text-decoration: none;
  color: #000;
}

.productListItemContainer {
  width: 100px;
  margin-bottom: 10px;
}

.productListItemThumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.productListItemTitle {
  margin: 0px;
  font-size: 12px;
}

.videoThumbnail {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  object-fit: cover;
}

span {
  font-size: 12px;
}

p {
  font-size: 12px;
}
