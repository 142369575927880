.container {
  max-width: 600px;
  margin: auto;
}

.headerContainer {
  padding: 20px 0px 20px 0px;
  align-vertical: 'middle';
}

.logo {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-right: 4px;
}

.logoTitle {
  font-size: 20px;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}

.uploaderContainer {
  display: flex;
  padding-left: 10px;
}

.userProfileImage {
  width: 30px;
  height: 30px;
  margin-right: 4px;
  border-radius: 24px;
  object-fit: cover;
}

.uploaderName {
  margin-top: auto;
  margin-bottom: auto;
}

.videoPlayer {
  margin-top: 10px;
  width: 100%;
  max-height: 300px;
}

.videoInfo {
  font-size: 10px;
  color: #666;
}

.sectionContainer {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-left: 10px;
}

.sectionTitle {
  font-size: 12px;
/*  font-weight: bold;*/
}

.videoRatingScore {
  font-size: 12px;
  font-weight: bold;
}

.linkedProductLink {
  text-decoration: none;
  color: #000;
  display: flex;
}

.linkedProductContainer {
  display: flex;
/*  margin: auto;*/
}

.linkedProductThumbnail {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.linkedProductDescription {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.discountPrice {
  margin-right: 10px;
}

.originalPrice {
  margin-right: 10px;
  text-decoration: line-through;
  color: #666;
}

.discountRate {
  color: #FDA408;
}

.title {
  font-weight: 500;
}

.description {
}

.videos-play {
  width: 100%;
  max-width: 640px;
  height: 100%;
  max-height: 400px;
  margin-left: 30%;
}

.product {
  background-color: white;
  width: 400px;

  height: 150px;
  margin-left: 10%;
}
.product-img {
  float: left;
  width: 100px;
  height: 100px;
}

.product-string {
  color: black;
}

.seller {
}

.seller-information {
  display: inline-block;
}

.seller-img-box {
  width: 40px;
  height: 40px;
  border-radius: 70%;
  overflow: hidden;
  margin-left: 10%;
}

.seller-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.seller-view {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.commentItemContainer {
  display: flex;
  margin-bottom: 10px;
}

.commentAuthorProfileImageContainer {
  margin-bottom: auto;
}

.commentInfoContainer {
  display: inline-block;
  margin-left: 10px;
}

.commentAuthorName {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.commentInfo {
  margin-top: auto;
  margin-bottom: auto;
  color: #666;
  font-size: 10px;
}

.comment {
  margin-top: 4px;
}

.linked-product {
  margin-left: 5%;
  margin-right: 10%;
}

.relate-video {
  margin-left: 5%;
  margin-right: 10%;
}

.videoThumbnail {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  object-fit: cover;
}

span {
  font-size: 12px;
}

p {
  font-size: 12px;
}
